/// MOCK COMPONENT
/// This means it's hastily written.
/// Here be dragons.

import NoteStack from '@/src/components/NoteStack/NoteStack';
import { DashboardContentSection } from '@/src/core/shared/Dashboard/DashboardContent';
import { useDevelopmentFlag } from '@/src/hooks/useDevelopmentFlag';
import GeneratedIcon from '@/src/icons/GeneratedIcon';
import { HomeSection, HomeSectionTitle } from '@/src/modules/home/components/HomeComponents';
import { useQueryResourceList } from '@/src/modules/resources/queries/useQueryResourceList';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { PrivateTag } from '@fabric/woody-client';
import Link from 'next/link';
import styled from 'styled-components';
import { useQueryTags } from '../../tags/queries/useQueryTags';
import { Flex } from '../../ui/components/Flex';
import ScrollArea from '../../ui/components/ScrollArea';

const TitleIcon = styled(GeneratedIcon).attrs({
  size: 16,
})`
  color: ${cssVar['color-text-primary']};
  opacity: 0.6;
`;

const GeneratedSpaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  gap: 18px;
  width: 206px;
  height: 236px;
  flex-shrink: 0;
  min-width: 183px;
  min-height: 220px;

  transition: background 0.2s;
  border-radius: 18px;
  padding: 16px;
  ${mediaHover} {
    &:hover {
      background: ${cssVar['color-bg-tertiary']};
    }
  }

  position: relative;
`;

const GeneratedSpaceTitle = styled.h5`
  overflow: hidden;
  color: #666;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;

const GeneratedSpaceStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 170px;

  position: relative;
  pointer-events: none;

  > div {
    position: absolute;
    width: 280px;
    height: 100%;
  }
`;

const AllEncompasingAnchor = styled(Link)`
  position: absolute;
  inset: 0;
  opacity: 0;
`;

const GeneratedSpace = (tag: PrivateTag) => {
  const { resources } = useQueryResourceList({
    tagIds: [tag.id],
  });

  const trimmedName = tag.name.slice(2);

  return (
    <GeneratedSpaceContainer>
      <AllEncompasingAnchor href={`/gen/${tag.id}`} />
      <GeneratedSpaceStack>
        <NoteStack resources={resources} scale={0.46} opacityTransition noTilt />
      </GeneratedSpaceStack>

      <GeneratedSpaceTitle>{trimmedName}</GeneratedSpaceTitle>
    </GeneratedSpaceContainer>
  );
};

export const GeneratedHomeList = () => {
  const featureEnabled = useDevelopmentFlag();

  const { data: tags } = useQueryTags('$$');

  if (!featureEnabled) {
    return null;
  }

  return (
    <HomeSection>
      <DashboardContentSection>
        <HomeSectionTitle>
          <TitleIcon />
          Generated
        </HomeSectionTitle>
      </DashboardContentSection>
      <ScrollArea orientation="horizontal" scrollbarVariant="none">
        <DashboardContentSection as={Flex} style={{ gap: '48px' }}>
          {tags?.map((tag) => <GeneratedSpace key={tag.id} {...tag} />)}
        </DashboardContentSection>
      </ScrollArea>
    </HomeSection>
  );
};
