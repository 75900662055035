import {
  DefaultResourceFilterOptions,
  ResourceDetail,
} from '@/src/modules/resources/resources.types';
import { FabricQueryMode } from '@fabric/woody-client';

export const defaultResourceFilterOptions: DefaultResourceFilterOptions = {
  sort: {
    field: 'modifiedAt',
    order: 'desc',
  },
  filters: {
    isRoot: false,
    hasSlug: false,
  },
  perPage: 30,
} as const;

export const defaultResourceSearchOptions = {
  mode: FabricQueryMode.Hybrid,
  text: '',
  pagination: {
    page: 1,
    pageSize: 30,
  },

  // no default sort, this lets the natural ElasticSearch ranking to take over, unless specified
} as const;

export const resourceKinds: ResourceDetail['kind'][] = [
  'audio',
  'bookmark',
  'default',
  'document',
  'highlight',
  'image',
  'notepad',
  'video',
  'folder',
];

export const resourceKindsExceptFolder: ResourceDetail['kind'][] = resourceKinds.filter(
  (kind) => kind !== 'folder',
);
