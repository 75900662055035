import { Flex } from '@/src/modules/ui/components/Flex';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

export const HomeSectionTitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: max-content;
  color: ${cssVar['color-text-primary']};
`;

export const HomeSection = styled(Flex).attrs({
  direction: 'column',
})`
  gap: 1.25rem;
`;

export const ResourceItemsContainer = styled(Flex).attrs<{ isNewPreviewCard?: boolean }>(
  (props) => {
    return {
      gap: 'elementsContainer',
      ...props,
    };
  },
)`
  height: calc(192px + 4px);
  padding-bottom: 4px;
  & > * {
    width: 168px;
    height: 192px;
  }
`;
