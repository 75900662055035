import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import React from 'react';
import styled, { css } from 'styled-components';

const ResourceCardIconContainer = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${cssVar['color-bg-primary']};
  color: ${cssVar['color-text-tertiary']};
`;

const Title = styled.div`
  color: ${cssVar['color-text-tertiary']};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  word-break: break-word;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface ResourceCardProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  children?: React.ReactNode;
  dropdown?: React.ReactNode;
  variant?: 'primary-inverse' | 'purple-pink';
  isNewPreviewCard?: boolean;
}
export const ResourcePreviewCompactCard = styled.div
  .attrs<ResourceCardProps>(({ icon, title, children, dropdown, ...props }) => {
    return {
      children: (
        <>
          <ResourceCardIconContainer>{icon}</ResourceCardIconContainer>
          <Title>{title}</Title>
          {children}
          {dropdown && <DropdownContainer>{dropdown}</DropdownContainer>}
        </>
      ),
      ...props,
    };
  })
  .withConfig(preventForwardPropsConfig(['isNewPreviewCard']))`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 13px;
  flex-shrink: 0;
  gap: 20px;
  overflow: hidden;

  aspect-ratio: ${cssVar['preview-compact-card-aspect-ratio']};

  border-radius: 18px;
  background: ${cssVar['color-bg-primary']};
  border: 1px solid ${cssVar['color-border-secondary']};
  box-shadow: ${cssVar['shadow-resource-card']};
  cursor: pointer;

  transition: background 0.2s;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--fabric-resource-hover-overlay-color);
    z-index: 10;
    pointer-events: none;
    transition: 0.1s;
    opacity: 0;
  }
  ${mediaHover} {
    &:hover:before {
      opacity: 1;
    }
  }
  &:active {
    &:before {
      opacity: 1;
    }
  }

  ${(p) => {
    switch (p.variant) {
      case 'primary-inverse':
        return css`
          background: #f3f0fe;
          border: none;

          &:hover {
            background: #e7e2fc;
          }
          ${ResourceCardIconContainer} {
            background: white;
          }
          ${Title}, ${ResourceCardIconContainer} {
            color: ${cssVar['color-app-primary']};
          }
        `;
      case 'purple-pink':
        return css`
          border: none;
          background: #a26ce4;
          ${mediaHover} {
            &:hover {
              background: #9462d0;
            }
          }
          &:active {
            background: #9462d0;
          }
          ${ResourceCardIconContainer} {
            background: rgba(255, 255, 255, 0.3);
          }
          ${Title}, ${ResourceCardIconContainer} {
            color: white;
          }
        `;
    }
  }}
`;
