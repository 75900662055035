import InvertedFabricIcon from '@/public/images/icons/Fabric.svg';
import GoogleDriveIcon from '@/public/images/icons/GoogleDrive.svg';
import { Link2Icon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import styles from './PrivacyConfirmContent.module.scss';

const PrivacyConfirmContent = () => {
  return (
    <div className={styles.content}>
      <div className={styles.icons}>
        <InvertedFabricIcon className={styles.fbc_icon} />
        <Link2Icon className={styles.link_icon} />
        <GoogleDriveIcon className={styles.google_icon} />
      </div>

      <div className={clsx(styles.policy_wrapper)}>
        <div className={clsx(styles.policy, 'dashboard_scrollbar')}>
          <h2>Our Privacy Commitment</h2>
          <h3>We are privacy-driven and safety-focused. We store as little data as possible.</h3>

          <p>
            We take the privacy and safety of our users&apos; data extremely seriously. This
            principle is built into our company culture, and every decision is made with this in
            mind. We strongly believe that personal data should never be abused for political or
            personal gain, and we will never hand over sensitive information to any law enforcement
            unless legally compelled to do so.
          </p>

          <ul>
            <li>
              Fabric uses Secure Sockets Layer (SSL)/Transport Layer Security (TLS) to protect data
              in transit
            </li>
            <li>
              Fabric files are encrypted at rest using 256-bit Advanced Encryption Standard (AES)
            </li>
            <li>
              SSL/TSL creates a secure tunnel protected by 128-bit or higher Advanced Encryption
              Standard (AES) encryption
            </li>
          </ul>

          <p>
            We store as little data as possible to create a great experience, and we will never
            store data that we don&apos;t use. We believe in the power of a good product and
            respecting our users, and we intend to honour that always.
          </p>

          <p className={styles.bold}>Fabric is built on these principles:</p>
          <ul>
            <li>
              Your data is yours, not ours. Delete anything (or everything) whenever you’d like.
            </li>
            <li>
              We will never sell your data. Data collection and/or sale is not the kind of business
              we’re in.
            </li>
            <li>
              We will always be honest and upfront about changes in the data we store, and we’ll
              provide clear details about why we need it. We will always send emails or push
              notifications about upcoming changes.
            </li>
            <li>
              We will always ask your permission when accessing services Fabric requires on your
              phone. Allow or deny, as you like, at any time.
            </li>
          </ul>

          <hr />

          <h6>What we collect and why</h6>
          <p>
            Our guiding principle is to collect only what we need. Here’s what that means in
            practice:
          </p>

          <h6>Identity & access</h6>

          <p>
            When you sign up for Fabric, we typically ask for identifying information such as your
            name and email address. If you sign up via Google, we only access your name, photo and
            email address, nothing more.
          </p>
          <p>
            We access your name and email so we can personalize your new account and send you
            invoices, updates or other essential information. We’ll never sell your personal info to
            third parties, and we won’t use your name in marketing statements without your
            permission either.
          </p>

          <h6>Billing information</h6>
          <p>
            When you pay for Fabric, we ask for your credit card and billing address. That&apos;s so
            we can charge you for our service, calculate taxes due and send you invoices. Your
            credit card is handled directly by our payment processor and doesn&apos;t ever go
            through our servers. We store a record of the payment transaction, including the last 4
            digits of the credit card number and your as-of billing address, for account history,
            invoicing and billing support. We store your billing address to calculate any sales tax
            due in the United States, to detect fraudulent credit card transactions and to print on
            your invoices
          </p>

          <h6>Website interactions</h6>
          <p>
            When you browse our marketing pages or applications, your browser automatically shares
            certain information such as which operating system and browser version you are using. We
            track that information in an anonymised form, along with the pages you are visiting,
            page load timing, and which website referred you, for statistical purposes like
            conversion rates, and to test new designs.
          </p>

          <hr />

          <h6>Data retention</h6>
          <p>
            At any point, you can delete your account and/or the data within it. Upon deletion, it
            will be purged from our systems within 30 days, after which point it will be entirely
            unrecoverable.
          </p>

          <h6>Data portability</h6>

          <p>
            In Fabric, you can always download entire folders or individual files to use elsewhere.
            Additionally, at any point, you can export all the data held in your Fabric account, and
            will receive it as a .ZIP file. In our early days, this will be a manual process which
            will be executed within 5 days. We&apos;ll automate this process as a priority in the
            next coming weeks. Our principle is simple: your data is yours, and we have no interest
            in getting in the way of that.
          </p>

          <h6>Changes & questions</h6>

          <p>
            We may update this policy as needed to comply with relevant regulations and reflect any
            new practices.
          </p>

          <hr />

          <h6>Data connections (optional)</h6>

          <p>These are opt-in additional features.</p>

          <h6>Google Drive</h6>

          <p>
            Fabric&apos;s use and transfer of information received from Google APIs to any other app
            will adhere to{' '}
            <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </p>

          <hr />

          <h6>3rd party partners</h6>

          <h6>Google</h6>
          <p>
            We use Google&apos;s OAuth offering as an optional sign-in method. When choosing to use
            this method, the user makes a specific to join to sign in via Google. Our public
            marketing site also uses Google Analytics to understand usage and traffic.
          </p>

          <h6>OpenAI</h6>
          <p>
            We use GPT4 by OpenAI to expand responses from our internal search system into rich
            responses, and to encode user NLP requests into search queries. This data is anonymised
            and is never used for model training. This feature is entirely optional and we make the
            partnership with OpenAI clearly visible before interacting with the feature.
          </p>

          <h6>Cloudflare</h6>
          <p>
            We use Cloudflare as a data storage partner, and all data is encrypted at-rest in the
            storage and in-transit to and from the storage.
          </p>

          <h6>AWS</h6>
          <p>
            We use AWS as a data storage partner, and all data is encrypted at-rest in the storage
            and in-transit to and from the storage. AWS also handles our authentication (login,
            logout, signup), and so securely stores email addresses and user names to support that.
          </p>

          <h6>Bunny</h6>
          <p>
            We use Bunny as a secure CDN to serve images at different resolutions to user that have
            access to see them. For example, if you save an image to Fabric, we use Bunny to be able
            to serve it to you at multiple sizes on any device with minimal latency.
          </p>

          <h6>Customer.io</h6>
          <p>
            We use Customer.io to deliver welcome emails to users, as well as updates about the
            product. You can unsubscribe to these at any time.
          </p>

          <h6>Twilio</h6>

          <p>
            We use Twilio to power our real-time in-app chat. All chat messages are encrypted
            in-storage.
          </p>

          <h6>Liveblocks</h6>
          <p>
            We use Liveblocks to power real-time collaboration features such as shared cursors, or
            to indicate shared presence when multiple users are together inside a shared document or
            space. Liveblocks only ever sees an anonymised ID value for the user, never anything
            more.
          </p>

          <hr />
          <p>
            Have any other questions about our privacy commitment? Email{' '}
            <a href="mailto:team@fabric.so">team@fabric.so</a>. We’re happy to answer those for you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyConfirmContent;
