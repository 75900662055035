import PrivacyConfirmContent from '@/src/components/Google/PrivacyConfirmContent';
import { ErrorMessages } from '@/src/core/config/errors';
import { ErrorType } from '@/src/core/types/errors';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useQueryResourceRootIntegrationList } from '@/src/modules/connections/queries/useQueryResourceRootIntegrationList';
import { useMutationCreateGoogleDriveMirror } from '@/src/modules/googleDrive/mutations/useMutationCreateGoogleDriveMirror';
import { useMutationDeleteGoogleDriveMirror } from '@/src/modules/googleDrive/mutations/useMutationDeleteGoogleDriveMirror';
import { useWoody } from '@/src/services/woody/woody';
import { confirm, toast } from '@/src/store/alerts';
import { isPlanBelow } from '@/src/types/pricing';
import { isErrorOfType } from '@/src/utils/error';
import { GoogleAccount, GoogleDriveDrive } from '@fabric/woody-client';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { create } from 'zustand';
import shallow from 'zustand/shallow';
import { useAuthUser } from '../auth';
import { Connection, useConnectionStore } from './useConnection';

export type GoogleConfirmArguments = {
  driveId: string;
  account: GoogleAccount;
};

interface GoogleConnectionStore {
  selectedAccount: GoogleAccount | null;
  setSelectedAccount: (account: GoogleAccount | null) => void;
}

const useGoogleConnectionStore = create<GoogleConnectionStore>()((set) => ({
  selectedAccount: null,
  setSelectedAccount: (account) => set({ selectedAccount: account }),
}));

/**
 * This is a more general hook to be used in the pending connection hook which handles
 * finalizing a connection with google drive. The objective is to use similar hooks for
 * further connection implementations.
 */
export const useGooglePending = () => {
  const { mutateAsync } = useMutationCreateGoogleDriveMirror();

  return useCallback(
    async ({ driveId, account }: GoogleConfirmArguments) => {
      const result = await mutateAsync({ accountId: account.id, driveId });

      if (!result) {
        throw new Error('Unable to create mirror');
      }

      return result.mirror.listId;
    },
    [mutateAsync],
  );
};

/**
 * This hook handles deleting an existing google drive mirror
 */
export const useGoogleDeleteMirror = () => {
  const { mutateAsync } = useMutationDeleteGoogleDriveMirror();

  return useCallback(
    async (folderId: string) => {
      // throws an error if the deletion fails
      await mutateAsync(folderId);
    },
    [mutateAsync],
  );
};

type DriveSelectionState = 'inactive' | 'explanation' | 'picker';

/**
 * This hook handles all logic needed to setup a google account, initializing the picker api
 * and handling the callback from the picker api to create a pending connection.
 */
const useGoogle = () => {
  const user = useAuthUser();
  const { client } = useWoody();
  const router = useRouter();
  const { setSelectedAccount, selectedAccount } = useGoogleConnectionStore();
  const [driveSelectionState, setDriveSelectionState] = useState<DriveSelectionState>('inactive');
  const { track } = useAnalytics();

  const { integrationRoots } = useQueryResourceRootIntegrationList();
  const exceededLimit = useMemo(
    // @TODO move the limit to a config file
    () => integrationRoots.filter((i) => i.subtype === 'GOOGLE').length >= 4,
    [integrationRoots],
  );

  const setPendingConnection = useConnectionStore((state) => state.setPendingConnection, shallow);

  // consume state and code query params from the url to finish setting up the connection
  const { state, code } = router.query;

  /**
   * This effect will check if state and code are present and consume it to exchange
   * with the credentials needed to use the picker api.
   */
  useEffect(() => {
    if (!state || !code) return;
    if (typeof state !== 'string' || typeof code !== 'string') return;

    const consumeGoogleAuth = async () => {
      const { data, error } = await client.exchangeGoogleOAuthCode(code, state);

      if (error) {
        setSelectedAccount(null);
        setDriveSelectionState('inactive');
        toast({
          content: 'Unable to connect to Google Drive. Please try again.',
        });
        router.replace(router.pathname, undefined, { shallow: true });

        return;
      }

      mutate('google-accounts');

      setSelectedAccount(data.account);
      setDriveSelectionState('explanation');

      track(AnalyticsEvents.ConfirmedAddGoogleAccount);

      router.replace(router.pathname, undefined, { shallow: true });
    };

    // remove
    consumeGoogleAuth();
  }, [state, code, router, client, setSelectedAccount, track]);

  useLayoutEffect(() => {
    if (!exceededLimit || driveSelectionState !== 'inactive') return;

    setDriveSelectionState('inactive');
    setSelectedAccount(null);
  }, [exceededLimit, driveSelectionState, setSelectedAccount]);

  /**
   * This returns the list of accounts used for selecting an already connected account
   */
  const { data: accounts, isLoading: isLoadingAccounts } = useSWR(
    'google-accounts',
    async () => {
      const { data, error } = await client.getGoogleAccounts();
      if (error) {
        throw error;
      }
      return data.accounts ?? [];
    },
    { revalidateOnFocus: true, revalidateOnMount: true, fallbackData: [] },
  );

  /**
   * This will redirect the user to the google oauth flow to connect a new account
   */
  const addNewConnection = async () => {
    if (isPlanBelow(user?.subscription.tier ?? 'free', 'pro')) {
      toast({
        content: ErrorMessages[ErrorType.FEATURE_NOT_AVAILABLE],
      });
      return;
    }

    const permitted = await confirm({
      id: 'google-privacy',
      title: 'Privacy Commitment',
      cancelLabel: 'Cancel',
      confirmLabel: 'Agree',
      fullScreen: true,
      content: PrivacyConfirmContent(),
    });

    if (!permitted) return;

    const { data, error } = await client.getGoogleDriveAuthorizationUrl(
      `${window.location.origin}/connections/google/account-selector`,
    );

    if (error) {
      if (isErrorOfType(ErrorType.FEATURE_NOT_AVAILABLE, error)) {
        toast({
          content: ErrorMessages[ErrorType.FEATURE_NOT_AVAILABLE],
        });
        return;
      }

      throw error;
    }

    track(AnalyticsEvents.ClickedAddGoogleAccount);

    router.push(data.url);
  };

  const confirmExplanation = () => {
    setDriveSelectionState('picker');
  };

  const startDriveSelection = useCallback(
    (googleAccount: GoogleAccount) => {
      setSelectedAccount(googleAccount);
      setDriveSelectionState('explanation');
    },
    [setSelectedAccount],
  );

  const onCancelPicker = () => {
    setDriveSelectionState('inactive');
    setSelectedAccount(null);
  };

  const onPickDrive = async (drive: GoogleDriveDrive) => {
    if (!selectedAccount) {
      setSelectedAccount(null);
      setDriveSelectionState('inactive');
      return;
    }

    setPendingConnection({
      connection: Connection.GOOGLE,
      name: drive.name,
      accountName: {
        value: selectedAccount.email,
        // split the username and domain, but keep the @ next to the domain
        parts: selectedAccount.email
          .split('@')
          .map((part, index, array) => (index < array.length - 1 ? part + '@' : part)),
      },
      arguments: {
        driveId: drive.id,
        account: selectedAccount,
      },
    });

    router.push('/connections/confirm');

    setSelectedAccount(null);
    setDriveSelectionState('inactive');
  };

  return {
    selectedAccount,
    accounts,
    addNewConnection,
    isLoadingAccounts: useMemo(
      () => isLoadingAccounts || (state && code),
      [isLoadingAccounts, state, code],
    ),
    driveSelectionState,
    confirmExplanation,
    startDriveSelection,
    onCancelPicker,
    onPickDrive,
    exceededLimit,
  };
};

export default useGoogle;
