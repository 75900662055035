import dayjs from 'dayjs';

/**
 * use it e.g. spaces.sort(sortByDateKey('createdAt'))
 * make sure the key you're comparing is a date string or a date
 */
export const sortByDateKey = <K extends string, T extends { [k in K]: Date | string | undefined }>(
  key: K,
  order: 'ASC' | 'DESC' = 'DESC',
) => {
  return (a: T, b: T) => {
    const orderVal = order === 'ASC' ? 1 : -1;

    if (!a[key] && !b[key]) {
      return 0;
    }

    if (!a[key]) {
      return orderVal;
    }

    if (!b[key]) {
      return -orderVal;
    }

    return dayjs(a[key]).isBefore(b[key]) ? orderVal * -1 : orderVal;
  };
};
