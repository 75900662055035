import ChevronRightThinIcon from '@/public/images/icons/ChevronRightThin.svg';
import RoundedBookmarkIcon from '@/public/images/icons/RoundedBookmark.svg';
import SimpleSpace from '@/src/components/SimpleSpace/SimpleSpace';
import { DashboardContentSection } from '@/src/core/shared/Dashboard/DashboardContent';
import { useResponsive } from '@/src/hooks/responsive';
import {
  HomeSection,
  HomeSectionTitle,
  ResourceItemsContainer,
} from '@/src/modules/home/components/HomeComponents';
import { ResourcePreviewCompactCard } from '@/src/modules/resources/components/ResourcePreviewCompactCard';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { LinkTouch } from '@/src/modules/ui/components/Link/LinkTouch';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { sortByDateKey } from '@/src/utils/sortBy';
import { useMemo } from 'react';

export const HomeRecentSpacesSection = () => {
  const { isMobileView } = useResponsive();

  const { spaceRoots } = useQueryResourceRootSpaceSystemList();
  const orderedSpaceRoots = useMemo(
    () => (spaceRoots || []).sort(sortByDateKey('modifiedAt')).slice(0, isMobileView ? 5 : 6),
    [spaceRoots, isMobileView],
  );

  return (
    <HomeSection>
      <DashboardContentSection>
        <HomeSectionTitle as={LinkTouch} href="/spaces">
          Recent spaces <ChevronRightThinIcon style={{ height: 12 }} />
        </HomeSectionTitle>
      </DashboardContentSection>
      <ScrollArea orientation="horizontal" scrollbarVariant="none">
        <DashboardContentSection>
          <ResourceItemsContainer>
            <ResourcePreviewCompactCard
              variant="purple-pink"
              title="Inbox"
              icon={<RoundedBookmarkIcon style={{ width: 19, height: 'auto' }} />}
              as={LinkTouch}
              href="/saved"
            />
            {orderedSpaceRoots.map((spaceRoot) => (
              <SimpleSpace
                enableDroppableTargetForResources
                forcedCompact
                key={spaceRoot.id}
                rootSpace={spaceRoot}
                resourceStackScaleMobile={0.27}
              />
            ))}
          </ResourceItemsContainer>
        </DashboardContentSection>
      </ScrollArea>
    </HomeSection>
  );
};
